export const msalConfig = {
  auth: {
    clientId: "668491e8-5de4-4bbf-9d1f-c27594d986e5",
    authority: "https://login.microsoftonline.com/7b81888c-6406-4003-abcd-7a965ccf8277", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin + "/redirect",
    postLogoutRedirectUri: window.location.origin + "/redirect"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
}
export const logoutRequest = {
  mainWindowRedirectUri: window.location.origin 
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}