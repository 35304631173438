import React, { useEffect } from 'react'; 

import { Link } from 'react-router-dom';

export default function TechList(prop) {
    useEffect(() => {
        
    }, []);
    
    return ( 
        <>
            
            <div className="tech_header" >
                <div className="header_inner">
                    <Link to={"/en/tech/list"}>
                        <img src="/resources/images/common/logo_bk.png" alt="" />
                    </Link>
                </div>
            </div>
        </>
    )
}