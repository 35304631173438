import React, { useEffect, useRef, useState } from "react";
import "rsuite/dist/rsuite.min.css";
import TechHeader from "./TechHeader";
import Footer from "../common/Footer";
import emailjs from "@emailjs/browser";
import { Form, ButtonToolbar, Button, Input } from "rsuite";
import { useNavigate } from "react-router-dom";

export default function TechSend(prop) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelectorAll(".header_wrap").forEach(function (element) {
      element.style.display = "none";
    });
  }, []);

  const lang = prop.lang;
  const Textarea = React.forwardRef((props, ref) => (
    <Input {...props} as="textarea" ref={ref} />
  ));

  let [sended, setSended] = useState(false);
  const form = useRef();

  let templateParams = {
    user_name: "",
    model: "",
    company: "",
    user_email: "",
    message: "",
  };

  const sendEmail = (e) => {
    console.log("sendEmail", templateParams);
    let elements;
    e.preventDefault();
    if (!templateParams.user_name) {
      //$('input[name="user_name"]').focus();
      document.querySelector('input[name="user_name"]').focus();
      document.querySelectorAll(".require_name").forEach(function (element) {
        element.style.display = "block";
      });

      return false;
    } else {
      document.querySelectorAll(".require_name").forEach(function (element) {
        element.style.display = "none";
      });
    }
    if (!templateParams.user_email) {
      //$('input[name="user_email"]').focus();
      document.querySelector('input[name="user_email"]').focus();
      document.querySelectorAll(".require_email").forEach(function (element) {
        element.style.display = "block";
      });

      return false;
    } else {
      document.querySelectorAll(".require_email").forEach(function (element) {
        element.style.display = "none";
      });
    }
    if (!templateParams.message) {
      //$('textarea[name="message"]').focus();
      document.querySelector('textarea[name="message"]').focus();
      document.querySelectorAll(".require_msg").forEach(function (element) {
        element.style.display = "block";
      });

      return false;
    } else {
      document.querySelectorAll(".require_msg").forEach(function (element) {
        element.style.display = "none";
      });
    }

    emailjs
      .send(
        "service_83tdmit",
        "template_jl40f7t",
        templateParams,
        "wT1_RJk7YLSJ7_z35"
      )
      .then(
        function (response) {
          setSended(true);
          //$("input, textarea").val("");
          templateParams = {
            user_name: "",
            model: "",
            company: "",
            user_email: "",
            message: "",
          };
          alert("Thank you for your inquiry. We will contact you soon.");
          navigate("/en/tech/list");
        },
        function (error) {
          alert("Failed to send your inquiry. Please try again later.");
          navigate("/en/tech/list");
          console.log("FAILED...", error);
        }
      );
    return;
  };
  return (
    <>
      <div id="container">
        <TechHeader />
        <div id="contents" className="tech_solution">
          <div className="tech_inner">
            <p className="tech_title">Technical Solution</p>
            <p className="form_tit">Contact Us</p>
            <div className="tech_form">
              <Form layout="horizontal" size={"sm"}>
                <Form.Group controlId="user_name">
                  <Form.ControlLabel>Username</Form.ControlLabel>
                  <Form.Control
                    name="user_name"
                    onChange={(obj) => {
                      templateParams.user_name = obj;
                    }}
                  />
                  <Form.HelpText className="require require_name">
                    Username is required
                  </Form.HelpText>
                </Form.Group>
                <Form.Group controlId="user_email">
                  <Form.ControlLabel>Email</Form.ControlLabel>
                  <Form.Control
                    name="user_email"
                    type="email"
                    onChange={(obj) => {
                      templateParams.user_email = obj;
                    }}
                  />
                  <Form.HelpText className="require require_email">
                    Email is required
                  </Form.HelpText>
                </Form.Group>
                <Form.Group controlId="company">
                  <Form.ControlLabel>Company</Form.ControlLabel>
                  <Form.Control
                    name="company"
                    onChange={(obj) => {
                      templateParams.company = obj;
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="model">
                  <Form.ControlLabel>Model</Form.ControlLabel>
                  <Form.Control
                    name="model"
                    onChange={(obj) => {
                      templateParams.model = obj;
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="msg">
                  <Form.ControlLabel>Message</Form.ControlLabel>
                  <Form.Control
                    rows={15}
                    name="msg"
                    accepter={Textarea}
                    onChange={(obj) => {
                      templateParams.message = obj;
                    }}
                  />
                  <Form.HelpText className="require require_msg">
                    Message is required
                  </Form.HelpText>
                </Form.Group>
                <Form.Group className="btn_wrap">
                  <ButtonToolbar>
                    <Button
                      appearance="primary"
                      className="btn_black"
                      onClick={sendEmail}>
                      Submit
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} className="mt0"></Footer>
    </>
  );
}
