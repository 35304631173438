/* eslint-disable */ 
import React, { useEffect, useState, useRef } from 'react'; 
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import $ from 'jquery';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation } from 'swiper';
import 'swiper/css';
import Modal from 'react-modal';
import emailjs from '@emailjs/browser';



export default function DhaulaPreorder(prop) {
    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
    const [thumbsSwiper3, setThumbsSwiper3] = useState(null);

    let [sended, setSended] = useState(false);

    let lang = prop.lang;



      const form = useRef();

    let templateParams = {
        nation : '',
        user_name : '',
        tel : '',
        company : '',
        user_email : '',
        amonut : '',
        message : ''
    };
      const sendEmail = (e) => {
        e.preventDefault();
        if (!templateParams.user_name) {
            $('input[name="user_name"]').focus();
            if (lang==="kr") {
                alert('이름을 입력해주세요'); 
            }
            else {
                alert("The field 'Name' is required."); 
            }
            return false;
        }
        if (!templateParams.nation) {
            $('input[name="nation"]').focus();
            if (lang==="kr") {
                alert('국가를 입력해주세요'); 
            }
            else {
                alert("The field 'Country' is required."); 
            }
            return false;
        }
        if (!templateParams.company) {
            $('input[name="company"]').focus();
            if (lang==="kr") {
                alert('회사를 입력해주세요'); 
            }
            else {
                alert("The field 'Company' is required."); 
            }
            return false;
        }
        if (!templateParams.user_email) {
            $('input[name="user_email"]').focus();
            if (lang==="kr") {
                alert('이메일을 입력해주세요'); 
            }
            else {
                alert("The field 'E-mail' is required."); 
            }
            return false;
        }
        if (!templateParams.amonut) {
            $('input[name="amount"]').focus();
            if (lang==="kr") {
                alert('구매수량을 입력해주세요'); 
            }
            else {
                alert("The field 'Amonut' is required."); 
            }
            return false;
        }
        if (!templateParams.message) {
            $('textarea[name="message"]').focus();
            if (lang==="kr") {
                alert('메세지를 입력해주세요');
            }
            else {
                alert("The field 'Message' is required."); 
            }
            return false;
        }
        $('.js-submit').addClass('disabled');
        
        //emailjs.send('service_94xp6yc', 'template_8w24avb', templateParams, 'hTl3KSgNf6lrUdTlm')
        emailjs.send('service_83tdmit', 'template_mf13xhq', templateParams, 'wT1_RJk7YLSJ7_z35')
            .then(function(response) {
               setSended(true);
               $('input, textarea').val('');
               templateParams = {
                    user_name : '',
                    nation:'',
                    tel : '',
                    company : '',
                    user_email : '',
                    amonut : '',
                    message : ''
                };
            }, function(error) {
               console.log('FAILED...', error);
            });

      };


    useEffect(() => {
        let page = window.location.search.split("=")[1];
        if (page) {
            window.scrollTo(0,$('.'+page).offset().top - $('.main-header').height());
        }
        else {
            window.scrollTo(0, 0);
        }
        $('.sol_acd .sol_item .item_head button').off('click').on('click', function() {
            let idx = $('.sol_acd .sol_item .item_head button').index($(this));
            for (let i=0; i<$('.sol_acd .sol_item .item_head button').length; i++) {
                if (idx === i) {
                    //$('.sol_acd .sol_item').eq(i).removeClass('on');
                    //$('.sol_acd .sol_item').eq(i).find('.item_body').stop().slideUp(300);
                    try {
                        $('#tech_v'+i)[0].currentTime=0;
                        $('#tech_v'+i)[0].play();
                    }
                    catch(e) {
                        console.log('')
                    }
                }
                else {
                    $('.sol_acd .sol_item').eq(i).removeClass('on');
                    $('.sol_acd .sol_item').eq(i).find('.item_body').stop().slideUp(300);
                    try {
                        $('#tech_v'+i)[0].pause();
                    }
                    catch(e) {
                        console.log('')
                    }
                }
            }
            $(this).closest('.sol_item').addClass('on');
            $(this).closest('.sol_item').find('.item_body').stop().slideDown(300);
        })

        $(window).on('scroll', (e)=>{
            let st = $(window).scrollTop();
            let wh = $(window).innerHeight();

            $('.js-fadeIn').each(function() {
                let this_top = $(this).offset().top;
                if (st+wh > this_top) {
                    $(this).addClass('on');
                }
            })
        })

        
        $(window).trigger('scroll');
        return () => {
            $(window).off('scroll');
        }

    }, []);

    return ( 
        <>
            <div id="container" className={lang}>
                <div id="contents" className="prd-dhaula-pre">
                    {/* <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>Dhaula Pre-order</span>
                                </p>
                            </div>
                        </div>
                    </div> */}
                    

                    <section className="section full-section page-product page_intro">
                        <section className="section basic-section js-fadeIn">
                            <div className="section-inner">
                                <div className="order_header">
                                    <div className="order_header_inner">
                                        <div className="order_header_tit">
                                            <span className="sub_tit">Maximun 3D & Minimal Design </span>
                                            <span className="main_tit">MOPIC Light Field 3D Display</span>
                                        </div>
                                        <div className="order_header_img">
                                            <video src="/resources/images/video/b2b_video.mp4" autoPlay muted loop></video>
                                        </div>
                                        {lang==="kr" ?
                                        <div className="order_header_cont">
                                            현존하는 최고의 3D 퍼포먼스를 자랑하는 라이트필드 3D 디스플레이를 지금 주문할 수 있습니다. <br />
                                            샘플을 구입하고, 당신의 시스템에 잘 결합할 수 있을지 충분히 고려해 보세요!<br />
                                            모든 샘플에는 기존 3D 콘텐츠와 호환되는 소프트웨어를 함께 제공합니다.<br />
                                            대량 구입 또는 커스텀 제작 문의도 가능합니다.
                                        </div>
                                        :
                                        <div className="order_header_cont">
                                            You can now order the Light Field 3D display boasting the highest 3D performance exists.  <br />
                                            Purchase a sample and carefully consider assessing its compatibility with your system.  <br />
                                            Each sample comes with software that is compatible with existing 3D content. <br />
                                            Inquiries for bulk orders or customizations are also available.
                                        </div>
                                        }
                                        <div className="order_header_img2">
                                            <img src="/resources/images/sub/order_img0.png" alt="" />
                                        </div>
                                        {lang==="kr" ?
                                        <div className="order_header_cont">
                                            MOPIC 라이트필드 3D 디스플레이는 평면 디스플레이 패널에 정밀한 3D 렌즈를 결합, <br />
                                            아이트래킹 카메라를 더해 3D 안경을 착용하지 않고도 입체적인 시청 경험을 제공합니다.
                                        </div>
                                        :
                                        <div className="order_header_cont">
                                            The MOPIC Lightfield 3D display combines a flat display panel with precise 3D lenses <br />
                                            and an eye-tracking camera to provide a 3D viewing experience without wearing 3D glasses.
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>

                    <section className="section full-section page-product page0">
                        <div className="section-inner">
                            <div className="dhaula_intro">
                                {/* <div className="intro_header">
                                    <p className="sub_tit">Maximum 3D & Minimal Design</p>
                                    <p className="title">MOPIC Dhaula</p>
                                </div> */}
                                <div className="intro_colswrap">
                                    <div className="intro_swiper_wrap col2">
                                        <div className="prd_swiper_img">
                                        <Swiper modules={[Thumbs, Navigation]} thumbs={{ swiper: thumbsSwiper }} navigation >
                                            <SwiperSlide><img src="/resources/images/product/dhaula_prd_000.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/dhaula_prd_01.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/dhaula_prd_02.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/dhaula_prd_03.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/dhaula_prd_04.png" alt="" /></SwiperSlide>
                                        </Swiper>
                                        </div>
                                        <div className="prd_swiper_thumb">
                                        <Swiper
                                            modules={[Thumbs]}
                                            watchSlidesProgress
                                            onSwiper={setThumbsSwiper}
                                            slidesPerView={5}
                                            spaceBetween={5}
                                        >
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/dhaula_prd_04.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/dhaula_prd_01.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/dhaula_prd_02.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/dhaula_prd_03.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/dhaula_prd_04.png" alt="" /></div></SwiperSlide>
                                        </Swiper>
                                        </div>
                                    </div>
                                    <div className="intro_cont col2">
                                        <p className="prd_tit">MOPIC Dhaula</p>
                                        {lang==="kr" ?
                                        <p className="prd_txt">
                                            15.6” 초경량 무안경 3D 디스플레이 Dhaula를 만나보세요. 모든 3D 콘텐츠와 호환되는 소프트웨어를 함께 제공합니다.
                                        </p>
                                        :
                                        <p className="prd_txt">
                                            Dhaula, 15.6” ultra-light glasses-free 3D display, comes with software compatible with all 3D content.
                                        </p>
                                        }
                                        <ul className="prd_link_list">
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/specification/SPECIFICATION_U15A731.pdf" target={'_blank'}>SPECIFICATION</a>
                                            </li>
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Dhaula_kr.pdf" target={'_blank'}>BROCHURE</a>
                                                
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Dhaula_en.pdf" target={'_blank'}>BROCHURE</a>
                                            </li>
                                            }
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_KR.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                                
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_EN.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                            </li>
                                            }
                                            {/* <li className="prd_link_item">
                                                <a href="#">Get Brochure</a>
                                            </li> */}
                                        </ul>
                                        <p className="order_date">
                                        {/*     Now - 30 April 2023 */} &nbsp;
                                        </p>
                                        <button className='link_order' onClick={()=>{
                                            var st = $('#order').offset().top;
                                            $('html, body').animate({scrollTop: st}, 1000);
                                        }}>ORDER NOW</button>
                                        <p className="desc_txt">* available to order for B2B customers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section full-section page-product page0">
                        <div className="section-inner">
                            <div className="dhaula_intro">
                                <div className="intro_colswrap">
                                    <div className="intro_swiper_wrap col2">
                                        <div className="prd_swiper_img">
                                        <Swiper modules={[Thumbs, Navigation]} thumbs={{ swiper: thumbsSwiper2 }} navigation >
                                            <SwiperSlide><img src="/resources/images/product/chooyu_prd_000.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/chooyu_prd_01.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/chooyu_prd_02.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/chooyu_prd_03.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/chooyu_prd_04.png" alt="" /></SwiperSlide>
                                        </Swiper>
                                        </div>
                                        <div className="prd_swiper_thumb">
                                        <Swiper
                                            modules={[Thumbs]}
                                            watchSlidesProgress
                                            onSwiper={setThumbsSwiper2}
                                            slidesPerView={5}
                                            spaceBetween={5}
                                        >
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/chooyu_prd_00.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/chooyu_prd_01.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/chooyu_prd_02.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/chooyu_prd_03.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/chooyu_prd_04.png" alt="" /></div></SwiperSlide>
                                        </Swiper>
                                        </div>
                                    </div>
                                    <div className="intro_cont col2">
                                        <p className="prd_tit">MOPIC Chooyu</p>
                                        {lang==="kr" ?
                                        <p className="prd_txt">
                                            32” 무안경 3D 디스플레이 Chooyu는 당신의 업무공간에 가장 잘 어울립니다.
                                            
                                        </p>
                                        :
                                        <p className="prd_txt">
                                            Chooyu, 32” glasses-free 3D display, fits best into your workspace.
                                        </p>
                                        }
                                        <ul className="prd_link_list">
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/specification/SPECIFICATION_U32A770.pdf" target={'_blank'}>SPECIFICATION</a>
                                            </li>
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Chooyu_kr.pdf" target={'_blank'}>BROCHURE</a>
                                                
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Chooyu_en.pdf" target={'_blank'}>BROCHURE</a>
                                            </li>
                                            }
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_KR.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                                
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_EN.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                            </li>
                                            }
                                            {/* <li className="prd_link_item">
                                                <a href="#">Get Brochure</a>
                                            </li> */}
                                        </ul>
                                        <p className="order_date">
                                        {/*     Now - 30 April 2023 */} &nbsp;
                                        </p>
                                        <button className='link_order' onClick={()=>{
                                            var st = $('#order').offset().top;
                                            $('html, body').animate({scrollTop: st}, 1000);
                                        }}>ORDER NOW</button>
                                        <p className="desc_txt">* available to order for B2B customers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section full-section page-product page0">
                        <div className="section-inner">
                            <div className="dhaula_intro">
                                {/* <div className="intro_header">
                                    <p className="sub_tit">Maximum 3D & Minimal Design</p>
                                    <p className="title">MOPIC Dhaula</p>
                                </div> */}
                                <div className="intro_colswrap">
                                    <div className="intro_swiper_wrap col2">
                                        <div className="prd_swiper_img">
                                        <Swiper modules={[Thumbs, Navigation]} thumbs={{ swiper: thumbsSwiper3 }} navigation >
                                            <SwiperSlide><img src="/resources/images/product/lhotse_prd_000.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/lhotse_prd_01.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/lhotse_prd_02.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/lhotse_prd_03.png" alt="" /></SwiperSlide>
                                            <SwiperSlide><img src="/resources/images/product/lhotse_prd_04.png" alt="" /></SwiperSlide>
                                        </Swiper>
                                        </div>
                                        <div className="prd_swiper_thumb">
                                        <Swiper
                                            modules={[Thumbs]}
                                            watchSlidesProgress
                                            onSwiper={setThumbsSwiper3}
                                            slidesPerView={5}
                                            spaceBetween={5}
                                        >
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/lhotse_prd_00.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/lhotse_prd_01.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/lhotse_prd_02.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/lhotse_prd_03.png" alt="" /></div></SwiperSlide>
                                            <SwiperSlide><div className="img_box"><img src="/resources/images/product/lhotse_prd_04.png" alt="" /></div></SwiperSlide>
                                        </Swiper>
                                        </div>
                                    </div>
                                    <div className="intro_cont col2">
                                        <p className="prd_tit">MOPIC Lhotse</p>
                                        {lang==="kr" ?
                                        <p className="prd_txt">
                                            65” 무안경 3D 디스플레이 Lhotse는 화면 전체에서 균일한 입체감을 보여줍니다.
                                        </p>
                                        :
                                        <p className="prd_txt">
                                            Lhotse, 65” glasses-free 3D display, displays a uniform stereoscopic effect across the entire screen.
                                        </p>
                                        }
                                        <ul className="prd_link_list">
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/specification/SPECIFICATION_U65A730.pdf" target={'_blank'}>SPECIFICATION</a>
                                            </li>
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Lhotse_kr.pdf" target={'_blank'}>BROCHURE</a>
                                                
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://download.mopiclabs.com/release/brochure/MOPIC%20Lhotse_en.pdf" target={'_blank'}>BROCHURE</a>
                                            </li>
                                            }
                                            {lang==="kr" ?
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_KR.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                            </li>
                                            :
                                            <li className="prd_link_item">
                                                <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_EN.pdf" target={'_blank'}>SUPPORTED SOFTWARE(MANUAL)</a>
                                            </li>
                                            }
                                            {/* <li className="prd_link_item">
                                                <a href="#">Get Brochure</a>
                                            </li> */}
                                        </ul>
                                        <p className="order_date">
                                        {/*     Now - 30 April 2023 */} &nbsp;
                                        </p>
                                        <button className='link_order' onClick={()=>{
                                            var st = $('#order').offset().top;
                                            $('html, body').animate({scrollTop: st}, 1000);
                                        }}>ORDER NOW</button>
                                        <p className="desc_txt">* available to order for B2B customers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section page-product page2">
                        <section className="section basic-section js-fadeIn">
                            <div className="section-inner">
                                {lang==="kr" ?
                                <p className="tech_title">다년간의 경험이 만들어 낸 최고의 기술 제품입니다</p>
                                :
                                <p className="tech_title">Our Technical Expertise is Our Greatest Product</p>
                                }
                                <ul className="tech_list">
                                    <li className="text_item item0">
                                        <div className="img_box">
                                            <div className="box_inner">
                                                <img src="/resources/images/product/13p_Eyetracking.gif" alt=""  className='tech_img_0'/>
                                            </div>
                                        </div>
                                        <div className="cont_box">
                                            <p className="tit">90 FPS Eyetracking</p>
                                            {lang==="kr" ?
                                            <p className="txt">초고속 아이트래킹을 적용하여 움직임에도 일관된 입체영상을 제공합니다.</p>
                                            :
                                            <p className="txt">The 90fps high-speed eye tracking provides consistent stereoscopic images even with user movements.</p>
                                            }
                                        </div>
                                    </li>
                                    <li className="text_item item1">
                                        <div className="img_box">
                                            <div className="box_inner">
                                                <img src="/resources/images/product/dhaula_5.gif" alt=""  className='tech_img_1'/>
                                            </div>
                                        </div>
                                        <div className="cont_box">
                                            <p className="tit">Low-Crosstalk</p>
                                            {lang==="kr" ?
                                            <p className="txt">영상 몰입을 방해하는 크로스톡 현상이 <br /> 매우 적습니다.</p>
                                            :
                                            <p className="txt">X-talk is very low, which interferes with 3D immersion. </p>
                                            }
                                        </div>
                                    </li>
                                    <li className="text_item item0">
                                        <div className="img_box">
                                            <div className="box_inner">
                                                <img src="/resources/images/product/dhaula_6.jpg" alt=""  className='tech_img_2'/>
                                            </div>
                                        </div>
                                        <div className="cont_box">
                                            <p className="tit">High-legibility</p>
                                            {lang==="kr" ?
                                            <p className="txt">고해상도 디스플레이 패널에 초정밀 광학렌즈를 결합, 디더링 알고리즘을 적용해 5pt의 작은 텍스트까지 잘 읽힙니다.</p>
                                            :
                                            <p className="txt">The ultra-precision optical lens is coupled to the high-resolution display panel, and a dithering algorithm is applied to read up to 5pt of small text.</p>
                                            }
                                        </div>
                                    </li>
                                </ul>
                                {lang==="kr" ?
                                <p className="feather_title">언제 어디서든 사용해 보세요!</p>
                                :
                                <p className="feather_title">You Can Use MOPIC Light Field 3D Display Anytime, Anywhere</p>
                                }
                                
                                <ul className="feature_list">
                                    <li className="feature_item">
                                        <div className="round_box rb0">
                                            {/* <img src="/resources/images/product/dhaula_feature_img0.png" alt="" /> */}
                                            <div className="inner_box"><span>Whatever</span></div>
                                        </div>
                                        {lang==="kr" ?
                                        <div className="feature_cont">
                                            <p className="tit">모든 3D와 호환</p>
                                            <p className="txt">모든 3D 그래픽, 3D 카메라 및 3D 기반 메타버스 콘텐츠와 호환됩니다.</p>
                                        </div>
                                        :
                                        <div className="feature_cont">
                                            <p className="txt">Compatible with all 3D graphics, 3D cameras, and 3D-based metaverse content.</p>
                                        </div>
                                        }
                                    </li>
                                    <li className="feature_item">
                                        <div className="round_box rb1">
                                            {/* <img src="/resources/images/product/dhaula_feature_img1.png" alt="" /> */}
                                            <div className="inner_box"><span>Whoever</span></div>
                                        </div>
                                        {lang==="kr" ?
                                        <div className="feature_cont">
                                            <p className="tit">Plug & Play</p>
                                            <p className="txt">플러그 앤 플레이 방식의 간단한 작동으로 3D 경험을 쉽게 만듭니다.</p>
                                        </div>
                                        :
                                        <div className="feature_cont">
                                            <p className="txt">The plug-and-play operation makes the 3D experience a breeze.</p>
                                        </div>
                                        }
                                    </li>
                                    <li className="feature_item">
                                        <div className="round_box rb2">
                                            {/* <img src="/resources/images/product/dhaula_feature_img2.png" alt="" /> */}
                                            <div className="inner_box"><span>Wherever</span></div>
                                        </div>
                                        {lang==="kr" ?
                                        <div className="feature_cont">
                                            <p className="tit">The Brightest 3D!</p>
                                            <p className="txt">500nit의 밝은 화면을 제공해 주변 조도의 영향을 거의 받지 않습니다.</p>
                                        </div>
                                        :
                                        <div className="feature_cont">
                                            <p className="txt">The bright screen is 500nit and rarely affected by ambient light. Therefore, it's easier to see the screen even in bright areas.</p>
                                        </div>
                                        }
                                    </li>
                                </ul>

                            </div>
                        </section>
                        <section className="section basic-section page-product page3 js-fadeIn">
                            <div className="section-inner">

                                
                                <div className="center-box">
                                    <div className="content-panel">
                                        <div className="sol_header">
                                            {lang==="kr" ?
                                            <p className="sec_title">MOPIC Light Field 3D는 다양한 산업 분야에 사용됩니다 </p>
                                            :
                                            <p className="sec_title">Lightfield 3D Display is Being Used in Various Industries</p>
                                            }
                                        </div>
                                        <div className="lf_sol_wrap">
                                            <div className="sol_video">
                                                
                                                <Swiper
                                                    onSwiper = {setSwiper} 
                                                    spaceBetween={0}
                                                    slidesPerView={1}
                                                    allowTouchMove = {false}
                                                    >
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v0" playsInline autoPlay muted loop poster='/resources/images/sub/lf_tech_img0.jpg'>
                                                                    <source src="/resources/images/video/product_Exhibition.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v1" playsInline autoPlay muted loop poster='/resources/images/sub/lf_tech_img0.jpg'>
                                                                    <source src="/resources/images/video/product_MedicalEducation.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v2" playsInline autoPlay muted loop poster='/resources/images/sub/lf_tech_img0.jpg'>
                                                                    <source src="/resources/images/video/product_Monitoring.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                {/* <video id="tech_v1" playsInline  muted loop poster='/resources/images/sub/lf_tech_img1.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_02.mp4" type="video/mp4" />
                                                                </video> */}
                                                                <img src="/resources/images/product/dhaula_11.jpg" alt="" />
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                {/* <video id="tech_v2" playsInline  muted loop poster='/resources/images/sub/lf_tech_img2.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_03.mp4" type="video/mp4" />
                                                                </video> */}
                                                                <img src="/resources/images/product/dhaula_12.jpg" alt="" />
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v5" playsInline  muted loop poster='/resources/images/sub/lf_tech_img3.jpg'>
                                                                    <source src="/resources/images/video/dhaula_video13.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    {/* <SwiperSlide>
                                                            <div className="img_wrap">
                                                                
                                                                <img src="/resources/images/product/dhaula_14.jpg" alt="" />
                                                            </div>
                                                    </SwiperSlide> */}
                                                            
                                                </Swiper>

                                                {/* <video playsInline autoPlay muted loop id="lf_video1">
                                                    <source src="/resources/images/temp/meta.mp4" type="video/mp4" />
                                                </video> */}
                                            </div>
                                            <div className="sol_acd">
                                                <div className="sol_item on">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(0);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">전시회 & 실감형 콘텐츠</span>
                                                            :
                                                            <span className="tit">Exhibition & User Experience</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body" style={{"display":"block"}}>
                                                        {lang==="kr" ?
                                                        <span>
                                                        3D 디스플레이에 공간 터치와 햅틱 센서를 결합하면, 입체 영상이 튀어나온 위치와 터치를 위한 공간 좌표를 일치시키고 입체영상을 터치하면 마치 홀로그램을 만지는 것 같은 궁극의 리얼한 경험을 제공할 수 있습니다.
                                                        </span>
                                                        :
                                                        <span>
                                                        In terms of user experience, it provides an ultimately immersive experience that feels like touching a hologram. <br />
                                                        MOPIC has already integrated your sensors with 3D display, and our customers are already fascinated.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sol_item">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(1);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">의료 교육 & 시뮬레이션</span>
                                                            :
                                                            <span className="tit">Medical Education & Simulation</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body">
                                                        {lang==="kr" ?
                                                        <span>
                                                        내시경 수술의 입체영상을 보여줌으로써 의료진이나 연수생의 교육효과를 높일 수 있습니다. <br />* 실시간으로 Side by Side 3D 영상을 재생할 수 있는 소프트웨어를 함께 제공합니다.
                                                        </span>
                                                        :
                                                        <span>
                                                        By showing stereoscopic images of endoscopic surgery, the simulation training of medical residents or trainees can be enhanced. We provide software to play Side by Side 3D videos in real time. <br />
                                                        * Our products include software that can convert Side by Side 3D image into Light Field 3D image in real time.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sol_item">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(2);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">원격 모니터링 & 화상통화</span>
                                                            :
                                                            <span className="tit">Monitoring & Video Call</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body">
                                                        {lang==="kr" ?
                                                        <span>
                                                        원격의 장소에 카메라 한 쌍을 설치해 3D 영상을 취득하고 실시간 전송하면, 디스플레이를 통해 실제 사람이 현장에서 보는 동일한 깊이감과 스케일의 입체영상을 보여줍니다.

                                                        </span>
                                                        :
                                                        <span>
                                                        A pair of cameras installed on a remote location acquires 3D images, and the display shows stereoscopic images with the same depth and scale that people actually see in the workspace.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sol_item">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(3);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">현미경 디지털 트랜스포메이션</span>
                                                            :
                                                            <span className="tit">Digital Transformation for Microscope</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body">
                                                        {lang==="kr" ?
                                                        <span>
                                                        더 이상 현미경 관찰을 위하여 목을 구부릴 필요가 없습니다. 접안렌즈에 카메라 한 쌍을 연결해 Side by Side 3D 영상을 취득하고, Dhaula를 통해 관찰하세요.
                                                        </span>
                                                        :
                                                        <span>
                                                        MOPIC Light Field 3D Display allows you to see your sample in a natural way without bending your neck or body. <br />
                                                        The 3D stereo image acquisition is done by connecting a pair of cameras to the eyepiece.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sol_item">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(4);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">3D 소프트웨어 개발</span>
                                                            :
                                                            <span className="tit">3D Software Development</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body">
                                                        {lang==="kr" ?
                                                        <span>
                                                        고객사가 MOPIC Light Field 3D Display와 함께 동작하는 3D 소프트웨어를 직접 개발할 수 있습니다. <br />* 3D 콘텐츠에서 최적의 입체감을 추출하는 MOPIC SDK를 함께 제공합니다.
                                                        </span>
                                                        :
                                                        <span>
                                                        The MOPIC SDK is a great tool that allows customers to develop their own 3D software that works with MOPIC Light Field 3D Display. The SDK extracts the optimal three-dimensional effect from the customer's 3D content.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sol_item">
                                                    <div className="item_head">
                                                        <button onClick={()=> {
                                                            swiper.slideTo(5);
                                                        }}>
                                                            {lang==="kr" ?
                                                            <span className="tit">3D 촬영 모니터</span>
                                                            :
                                                            <span className="tit">Monitor for 3D VR shooting</span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="item_body">
                                                        {lang==="kr" ?
                                                        <span>
                                                        MOPIC Light Field 3D Display는 가장 완벽한 가상현실 뷰어 입니다. 촬영한 3D VR 영상의 3차원 효과를 HMD 착용 없이 가장 쉽게 확인할 수 있습니다. 
                                                        </span>
                                                        :
                                                        <span>
                                                        MOPIC Light Field 3D Display is the most complete virtual reality viewer in the world. It is the easiest way to check the three-dimensional effect of the 3D VR video you took. you don't need to wear HMD to see how the video looks in 3D.
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </section>
                    
                    <section className="section full-section page-product-order ">
                    <section className="section basic-section page-product page4 js-fadeIn" id="order">
                        <div className="section-inner">
                            <div className="order_title">
                                {lang==="kr" ?
                                <h2>구입 문의</h2>
                                :
                                <h2>Order for B2B only</h2>
                                }
                            </div>
                           <div className= { sended ? "order_wrap sended" : "order_wrap" }>
                                { sended ? 
                                <div className="order_img">
                                    <div className="video_wrap">
                                        <video id="order1" playsInline  muted loop autoPlay>
                                            <source src="/resources/images/video/dhaula_preorder2.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <div className="cont_box">
                                        {lang==="kr" ?
                                        <p className="tit">감사합니다! <br /> 영업일 기준 7일 이내에 연락 드리겠습니다.</p>
                                        :
                                        <p className="tit">Thanks for contacting us. <br /> We'll reply within 7 business day.</p>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="order_img">
                                    <div className="video_wrap">
                                        <video id="order2" playsInline  muted loop autoPlay>
                                            <source src="/resources/images/video/dhaula_preorder.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <div className="cont_box">
                                        <p className="tit">Experience MOPIC Light Field 3D Display! <br />A highly advanced Light Field 3D</p> 
                                    </div>
                                    
                                </div>
                                }
                                { sended ? null : 
                                <div className="order_modal">
                                    <form ref={form} onSubmit={sendEmail}>
                                        {lang==="kr" ?
                                        <div className="consulting_box">
                                            <div className="consult_cont">
                                                <label htmlFor="cs_name" className="label_text">이름<em className="must star">*</em></label>
                                                <input type="text" id="cs_name" name="user_name" placeholder="" onChange={(e)=> {
                                                    templateParams.user_name = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_nation" className="label_text">국가<em className="must star">*</em></label>
                                                <input type="text" id="cs_nation" name="nation" placeholder="" onChange={(e)=> {
                                                    templateParams.nation = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_tel" className="label_text">연락처</label>
                                                <input type="text" id="cs_tel" name="tel" placeholder="" onChange={(e)=> {
                                                    templateParams.tel = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_company" className="label_text">회사명<em className="must star">*</em></label>
                                                <input type="text" id="cs_company" name="company" placeholder="" onChange={(e)=> {
                                                    templateParams.company = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_email" className="label_text">이메일 주소<em className="must star">*</em></label>
                                                <input type="text" id="cs_email" name="user_email" placeholder="" onChange={(e)=> {
                                                    templateParams.user_email = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_amount" className="label_text">구매 수량<em className="must star">*</em></label>
                                                <input type="number" id="cs_amount" name="amount" placeholder="" onChange={(e)=> {
                                                    templateParams.amonut = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont">
                                                <label htmlFor="cs_company" className="label_text">메세지<em className="must star">*</em></label>
                                                <textarea cols="30" rows="10" name="message" id="cs_message" className="" 
                                                placeholder='가격, 최소 주문 수량 및 커스터마이징 등 구매 관련 질문을 남겨 주시면 모픽 영업팀에서 연락 드립니다.'
                                                onChange={(e)=> {
                                                    templateParams.message =  e.currentTarget.value;
                                                }}></textarea>
                                            </div>
                                            <div className="btn_wrap">
                                                <input type="submit" value="SEND" className='btn btn_sm btn_blue js-submit'/>
                                            </div>
                                        </div>
                                        :
                                        <div className="consulting_box">
                                            <div className="consult_cont">
                                                <label htmlFor="cs_name" className="label_text">Name<em className="must star">*</em></label>
                                                <input type="text" id="cs_name" name="user_name" placeholder="" onChange={(e)=> {
                                                    templateParams.user_name = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_nation" className="label_text">Country<em className="must star">*</em></label>
                                                <input type="text" id="cs_nation" name="nation" placeholder="" onChange={(e)=> {
                                                    templateParams.nation = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_tel" className="label_text">Contact</label>
                                                <input type="text" id="cs_tel" name="tel" placeholder="" onChange={(e)=> {
                                                    templateParams.tel = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_company" className="label_text">Company<em className="must star">*</em></label>
                                                <input type="text" id="cs_company" name="company" placeholder="" onChange={(e)=> {
                                                    templateParams.company = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_email" className="label_text">Email<em className="must star">*</em></label>
                                                <input type="text" id="cs_email" name="user_email" placeholder="" onChange={(e)=> {
                                                    templateParams.user_email = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont col_half">
                                                <label htmlFor="cs_amount" className="label_text">Qty<em className="must star">*</em></label>
                                                <input type="number" id="cs_amount" name="amount" placeholder="" onChange={(e)=> {
                                                    templateParams.amonut = e.currentTarget.value;
                                                }} />
                                            </div>
                                            <div className="consult_cont">
                                                <label htmlFor="cs_company" className="label_text">Message<em className="must star">*</em></label>
                                                <textarea cols="30" rows="10" name="message" id="cs_message" className=""
                                                placeholder='If you have any questions, please submit this form to contact MOPIC Sales for more information such as pricing, minimum order quantities and customizing.'
                                                 onChange={(e)=> {
                                                    templateParams.message =  e.currentTarget.value;
                                                }}></textarea>
                                            </div>
                                            <div className="btn_wrap">
                                                <input type="submit" value="SEND" className='btn btn_sm btn_blue js-submit'/>
                                            </div>
                                        </div>
                                        }
                                    </form>
                                </div>
                                }
                            </div>
                        </div>
                    </section>
                    </section>
                    
                </div>
            </div>
            
            <Footer lang={lang} className="mt0"></Footer>
            
            
        </>
    ); 
}; 